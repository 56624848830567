import {
  decodeCreationOptions,
  encodeCreationCredential,
  decodeRequestOptions,
  encodeRequestCredential,
  CustomPublicKeyCredentialRequestOptions,
  creationCredential
} from './utils';
import { sallaFetch } from '../../lib/api';

export async function registerPasskey(salla: any) {
  try {
    // Step 1: Request registration options from the server
    const options = await salla.api
      .request('webauthn/keys/options', '', 'post')
      .then((res: any) => res.publicKey);

    // Step 2: Create a new credential using the registration options
    const cred = (await navigator.credentials.create({
      publicKey: decodeCreationOptions(options),
    })) as PublicKeyCredential;

    if (!cred) {
      throw new Error('Failed to create credentials');
    }

    // Step 3: Register the newly created credential with the server
    await salla.api.request(
      'webauthn/keys',
      encodeCreationCredential(cred),
      'post'
    );

    return 'Passkey registered successfully!';
  } catch (error) {
    throw error;
  }
}

export async function authenticatePasskey(
  salla: any,
  options: CustomPublicKeyCredentialRequestOptions
) {
  try {
    const cred = (await navigator.credentials.get({
      publicKey: decodeRequestOptions(options),
    })) as PublicKeyCredential;

    if (!cred) {
      throw new Error('Failed to get credentials');
    }

    const res = await salla.api.request(
      'webauthn/verify',
      { ...encodeRequestCredential(cred), key: options.key },
      'post'
    );
    if (res.success) {
      return res;
    } else {
      throw new Error('Failed to verify credentials');
    }
  } catch (error) {
    throw error;
  }
}

export async function registerPasskeyWithFetch(headers?: HeadersInit) {
  try {
    // Step 1: Request registration options from the server
    const options = await sallaFetch({ endpoint: 'webauthn/keys/options', method: 'post', headers })
      .then((res: any) => res.data.publicKey);

    // Step 2: Create a new credential using the registration options
    const cred = (await navigator.credentials.create({
      publicKey: decodeCreationOptions(options),
    })) as PublicKeyCredential;

    if (!cred) {
      throw new Error('Failed to create credentials');
    }

    interface MyRequestParams {
      params: creationCredential;
    }

    // Step 3: Register the newly created credential with the server
    await sallaFetch<MyRequestParams>({
      endpoint: 'webauthn/keys',
      params: encodeCreationCredential(cred),
      method: 'post',
      headers
    });

    return 'Passkey registered successfully!';
  } catch (error) {
    throw error;
  }
}
