const ErrorIcon: React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <svg
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
        className="w-20 h-20"
      >
        <g
          stroke="currentColor"
          fill="none"
          fillRule="evenodd"
          strokeLinejoin="round"
        >
          <path
            className="circle animate-draw-circle text-red-300"
            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
            style={{
              strokeWidth: 1,
              strokeDasharray: 76,
              strokeDashoffset: 76,
              animation: 'draw 1s forwards',
            }}
          />
          <path
            className="cross animate-draw-tick text-red-500"
            d="M9 9L17 17M17 9L9 17"
            style={{
              strokeWidth: 1.5,
              strokeDasharray: 24,
              strokeDashoffset: 24,
              animation: 'draw 1s forwards 1s',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export default ErrorIcon;