import React, { useEffect, useState, useRef, type SyntheticEvent } from 'react';
import Verify from './Verify';
import TelInput from './TelInput';
import Loading from './Loading';
import { FileUploader } from './FileUploader';
import { type CustomField, CustomFieldType } from './interfaces';
import { parentMessage } from '../common/utils';
import BiometricCTA from './Biometric';
import { isBiometricSupported } from './Biometric/utils';
import { authenticatePasskey } from './Biometric/actions';
import useMutation from '../hooks/useMutation';
import { useTranslation } from 'react-i18next';
interface LoginProps {
  isEmailAllowed: boolean;
  isMobileAllowed: boolean;
  isEmailRequired: boolean;
  supportWebAuth: boolean;
  withoutReload: boolean;
  salla: any;
  inline: boolean;
}
interface VerifyTabRef {
  open: (data: any) => void;
  getCode: () => Promise<string>;
}
interface TelInputRef {
  getValues: () => Promise<{ [key: string]: string }>;
  isValid: () => Promise<boolean>;
}

const Login: React.FC<LoginProps> = ({
  isEmailAllowed,
  isMobileAllowed,
  isEmailRequired,
  supportWebAuth,
  withoutReload,
  salla
}) => {
  const [currentTabName, setCurrentTabName] = useState('home');
  // eslint-disable-next-line
  const [phone, setPhone] = useState({ number: '', country_code: '' });

  const [showVerify, setShowVerify] = useState(false);
  const [emailErrorMsg] = useState(salla.lang.get('common.elements.email_is_valid'));
  const [firstNameErrorMsg] = useState(salla.lang.get('common.errors.field_required', { attribute: salla.lang.get('pages.profile.first_name') }));
  const [lastNameErrorMsg] = useState(salla.lang.get('common.errors.field_required', { attribute: salla.lang.get('pages.profile.last_name') }));
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForceOtp, setIsForceOtp] = useState(false);
  // Refs
  const mobileTab = useRef(null);
  const emailTab = useRef(null);
  const verifyTab = useRef(null);
  const verifyRef = useRef<VerifyTabRef | null>(null);
  const loginEmail = useRef<HTMLInputElement>(null);
  const smsBtn = useRef(null);
  const emailBtn = useRef(null);
  const loginTelInput = useRef<TelInputRef | null>(null);

  // Register vars
  const [regType, setRegType] = useState('phone');
  const [customFieldsValues, setCustomFieldsValues] = useState<{ [key: string]: string }>({});

  const customFieldsWrapperRef = useRef<HTMLDivElement | null>(null);
  // Register Refs
  const regBtn = useRef(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const regTelInput = useRef<TelInputRef | null>(null);
  const registrationTab = useRef(null);
  const enableBiometricTab = useRef(null);
  const regEmail = useRef<HTMLInputElement>(null);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const { t } = useTranslation();

  const authenticatePasskeyMutation = useMutation<any, any, Error | any>(
    authenticatePasskey,
    {
      onSuccess: (data) => {
        parentMessage({ message: t('passkey_verify_success'), type: 'success' }, 'notify');
        salla.logger.log(data);
        handleVerified(data, false);
      },
      onError: (error) => {
        parentMessage(
          {
            message: t('passkey_verify_error'),
            type: 'error',
          },
          'notify'
        );
        salla.logger.error(error?.message);

        // in case of error send fallback to BE to verify the user with OTP
        handlePassKeyFallBack();
      },
    }
  );

  const handlePassKeyFallBack = () => {
    salla.logger.log('handlePassKeyFallBack with login with otp')
    setIsForceOtp(true);
    if (emailTab && loginEmail.current && emailBtn.current) {
      loginByEmail(null, true);
    } else if (mobileTab) {
      loginBySMS(null, true);
    }
  };


  function generateTabClasses(tabName: string) {
    const classes = {
      's-login-modal-tab': tabName !== 'registration' && tabName !== 'otp' && tabName !== 'enableBiometricTab',
      "s-hidden": currentTabName !== tabName,
      "s-show": currentTabName === tabName,
      's-login-modal-unactive': tabName !== 'registration' && tabName !== 'enableBiometricTab' && tabName !== 'otp' && currentTabName !== tabName,
      's-login-modal-active': tabName !== 'registration' && tabName !== 'enableBiometricTab' && tabName !== 'otp' && currentTabName === tabName
    }

    return Object.entries(classes)
      .filter(([className, shouldApply]) => shouldApply)
      .map(([className]) => className)
      .join(' ');
  }

  function generateRegClasses(type: string) {
    const classes = {
      "mb-1.5": true,
      "s-hidden": regType === type
    }
    return Object.entries(classes)
      .filter(([_, shouldApply]) => shouldApply)
      .map(([className]) => className)
      .join(' ');
  }

  function typing(e: React.KeyboardEvent<HTMLInputElement>, submitMethod: (() => void) | null = null) {
    const error = (e.target as HTMLInputElement).nextElementSibling;
    (e.target as HTMLElement).classList.remove('s-has-error');
    if (error?.classList.contains('s-login-modal-error-message')) {
      ((error as HTMLElement).innerText = '');
    }
    setHasError(false);
    setErrorMessage('');
    //it was sending two requests for send two verification requests
    submitMethod && e.key === 'Enter' && (submitMethod as () => void)();
  }

  const handleVerified = (res: any, showPasskey = true) => {
    salla.log('verified', res);
    const verifyCase = res.data.case || res.error.case;

    if (
      isBiometricSupported &&
      enableBiometricTab.current &&
      showPasskey &&
      verifyCase !== 'new_customer' &&
      !isForceOtp
    ) {
      parentMessage(
        {
          ...res.data,
          withoutClose: true,
          withoutReload: true,
        },
        'authenticated'
      );

      showTab(enableBiometricTab.current);
      return;
    }

    if (!verifyCase) {
      salla.log('verified but without case!');
      return;
    }
    if (verifyCase === "new_customer") {
      setCustomFields(res.data.custom_fields || []);
      return registrationTab.current && showTab(registrationTab.current);
    }
    if (res.data.redirect_url) {
      window.location.href = res.data.redirect_url;
      return window.location.href
    }
    
    parentMessage(res.data, (supportWebAuth && !withoutReload) ? "authenticated_web_auth" : "authenticated");
  }

  function validateField(field: HTMLInputElement, errorMsg: string) {
    if (!field) {
      return
    }
    field.classList.add('s-has-error');
    const nextSibling = field.nextElementSibling;
    if (nextSibling) {
      (nextSibling as HTMLElement).innerText = `* ${errorMsg}`;
    }
  }
  async function newUserValidation() {
    const isLogByPhone = regType === "phone";
    const isLogByEmail = regType === "email";
    const emailValue = (regEmail.current?.value) || (isLogByEmail && loginEmail.current && loginEmail.current.value);
    const isEmailValid = emailValue && isValidEmail(emailValue);
    const isFirstNameValid = firstName.current && firstName.current.value.trim().length > 0;
    const isLastNameValid = lastName.current && lastName.current.value.trim().length > 0;
    const isPhoneValid = (regTelInput.current && await regTelInput.current.isValid()) || (loginTelInput.current && isLogByPhone && await loginTelInput.current.isValid());
    const emailValidation = (emailValue && isEmailValid) || (!emailValue && !isEmailRequired);

    // Custom Fields Validation
    let isCustomFieldValid = true;
    //we should make sure that there is text nodes between children
    customFieldsWrapperRef.current && (customFieldsWrapperRef.current as HTMLElement)?.childNodes
      .forEach((field: any | HTMLInputElement) => {
        setCustomFieldsValues(prevState => ({
          ...prevState,
          [field.id]: field.value
        }));
        if (!field.required || customFieldsValues[field.id].length) {
          return;
        }
        isCustomFieldValid = false;
        const errorMsg: string = salla.lang.get('common.errors.field_required', { attribute: field.title });
        validateField(field, errorMsg)
      });

    if (emailValidation && isPhoneValid && isFirstNameValid && isLastNameValid && isCustomFieldValid) return;
    !isEmailValid && regEmail.current && validateField(regEmail.current, emailErrorMsg);
    !isFirstNameValid && firstName.current && validateField(firstName.current, firstNameErrorMsg);
    !isLastNameValid && lastName.current && validateField(lastName.current, lastNameErrorMsg);
    throw new Error('Please insert required fields');
  }

  const newUser = async () => {
    let regPhone = '';
    let countryCode = '';

    if (regType === "email" && regTelInput.current) {
      const values = await regTelInput.current.getValues();
      if (values && 'phone' in values && 'countryCode' in values) {
        regPhone = values.phone;
        countryCode = values.countryCode;
      }
    } else if (loginTelInput.current) {
      const values = await loginTelInput.current.getValues();
      if (values && 'phone' in values && 'countryCode' in values) {
        regPhone = values.phone;
        countryCode = values.countryCode;
      }
    }

    const emailValue = (regEmail.current?.value) || (loginEmail.current?.value);

    await newUserValidation();
    setIsLoading(true);

    let data = {
      first_name: firstName.current?.value,
      last_name: lastName.current?.value,
      phone: regPhone || (loginTelInput.current?.getValues().then((values) => values.phone)),
      country_code: countryCode,
      verified_by: regType,
      custom_fields: customFieldsValues,
      email: ''
    };

    if (emailValue) {
      data = { ...data, email: emailValue }
    }

    const code = verifyRef.current?.getCode();

    salla.auth.api
      .register({ ...data, code })
      // After registration is done show biometric cta
      .then((res: any) => {
        if (isBiometricSupported) {
          setTimeout(() => {
            enableBiometricTab.current && showTab(enableBiometricTab.current);
            parentMessage(
              {
                ...res.data,
                withoutClose: true,
                withoutReload: true,
                verification: { type: data.verified_by },
              },
              'authenticated'
            );
          }, 1000);

          return;
        }

        parentMessage({...res.data, verification: { type: regType }}, (supportWebAuth && !withoutReload) ? "authenticated_web_auth" : "authenticated");
      })
      .then(() => setIsLoading(false))
      .catch((error: Error) => {
        salla.logger.error(error);
        setIsLoading(false);
      });
  }



  async function loginBySMS(
    event: SyntheticEvent | null = null,
    forceOtp: boolean = false
  ): Promise<void> {
    event?.preventDefault();
    const {
      phone = '',
      countryCode = '',
      countryKey = '',
    } = loginTelInput.current ? await loginTelInput.current.getValues() : {};
    const isPhoneValid =
      loginTelInput.current && (await loginTelInput.current.isValid());

    if (!isPhoneValid) {
      return;
    }

    smsBtn.current &&
      login(smsBtn.current, {
        type: 'mobile',
        phone,
        country_code: countryCode,
        country_key: countryKey,
        ...(forceOtp && { force_otp: true }),
      });
  }

  function isValidEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  const loginByEmail = (
    event: React.FormEvent<HTMLFormElement> | null = null,
    forceOtp: boolean = false
  ) => {
    event?.preventDefault();
    if (loginEmail.current && !isValidEmail(loginEmail.current.value)) {
      setHasError(true);
      setErrorMessage(`* ${emailErrorMsg}`);
      return;
    }
    if (loginEmail.current && emailBtn.current) {
      login(emailBtn.current, {
        type: 'email',
        email: loginEmail.current.value,
        ...(forceOtp && { force_otp: true }),
      });
    }
  };

  const handlePassKeyAuthentication = (options: any) => {
    if (isBiometricSupported) {
      authenticatePasskeyMutation.mutate(salla, options);
    } else {
      //The user has passkey but not in current device
      //TODO: send fallback to BE to verify the user with OTP, since this device is not registered
      handlePassKeyFallBack();
    }
  };


  const login = (_btn: HTMLElement, data: any) => {
    setIsLoading(true);
    setHasError(false);
    setErrorMessage('');
    salla.auth.api.login(data)
      .then((res: any) => {
        setIsLoading(false);
        // PassKey Verification Part
        // check the res if the user has already passkey
        if (res?.publicKey) return res;
        verifyTab.current && showTab(verifyTab.current as unknown as HTMLElement)
      })
      .then((resData: any) => {
        if (resData?.publicKey) {
          handlePassKeyAuthentication({...resData.publicKey, key: resData.key });
          return;
        };
        setShowVerify(true);
        setTimeout(() => {
          verifyRef.current?.open(data)
        }, 100);
      })
      .catch((error: any) => {
        setHasError(true);
        setIsLoading(false);
        setErrorMessage(error.response.data?.error?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const getFilepondPlaceholder = () => {
    return `<div class="s-login-modal-filepond-placeholder"><span class="s-login-modal-filepond-placeholder-icon"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>camera</title>
    <path d="M16 10.667c-4.044 0-7.333 3.289-7.333 7.333s3.289 7.333 7.333 7.333 7.333-3.289 7.333-7.333-3.289-7.333-7.333-7.333zM16 22.667c-2.573 0-4.667-2.093-4.667-4.667s2.093-4.667 4.667-4.667 4.667 2.093 4.667 4.667-2.093 4.667-4.667 4.667zM28 5.333h-3.287l-2.271-3.407c-0.248-0.371-0.664-0.593-1.109-0.593h-10.667c-0.445 0-0.861 0.223-1.109 0.593l-2.271 3.407h-3.287c-2.205 0-4 1.795-4 4v17.333c0 2.205 1.795 4 4 4h24c2.205 0 4-1.795 4-4v-17.333c0-2.205-1.795-4-4-4zM29.333 26.667c0 0.735-0.599 1.333-1.333 1.333h-24c-0.735 0-1.333-0.599-1.333-1.333v-17.333c0-0.735 0.599-1.333 1.333-1.333h4c0.445 0 0.861-0.223 1.109-0.593l2.272-3.407h9.239l2.271 3.407c0.248 0.371 0.664 0.593 1.109 0.593h4c0.735 0 1.333 0.599 1.333 1.333zM25.333 10.66c-0.736 0-1.333 0.604-1.333 1.34s0.597 1.333 1.333 1.333 1.333-0.597 1.333-1.333v-0.013c0-0.736-0.597-1.327-1.333-1.327z"></path>
    </svg></span><p class="s-login-modal-filepond-placeholder-text">${salla.lang.get('common.uploader.drag_and_drop')}</p> <span class="filepond--label-action">${salla.lang.get('common.uploader.browse')}</span></div>`
  }

  function showTab(tab: HTMLElement, evt?: React.MouseEvent) {
    evt?.preventDefault();
    setHasError(false);
    setErrorMessage('');
    setCurrentTabName((tab as HTMLInputElement).getAttribute('data-name') || '');

    if (!tab) return;
    if ([mobileTab.current, emailTab.current].includes(tab as any)) {
      setRegType(tab === mobileTab.current ? 'phone' : 'email');
    }
    
    if (tab === registrationTab.current) {
      return;
    }

    handleAutoFocusOnFirstLoad(tab);
  }


  function getSelectedTab() {
    if (isMobileAllowed) {
      mobileTab.current && showTab(mobileTab.current);
    } else if (isEmailAllowed) {
      emailTab.current && showTab(emailTab.current);
    }
  }

  function handleAutoFocusOnFirstLoad(tab?: HTMLElement) {
    const container = tab || document;
    const input = container.querySelector(
      'input[type="tel"], input[type="email"]'
    ) as HTMLInputElement | null;
    input &&
      setTimeout(() => {
        input.focus();
      }, 100);
  }

  function handleBackButton() {
    if (mobileTab.current && regType === 'phone') {
      showTab(mobileTab.current);
    } else if (emailTab.current && regType === 'email') {
      showTab(emailTab.current);
    }
  }


  useEffect(() => {
    getSelectedTab();
    handleAutoFocusOnFirstLoad();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={'s-login-modal-wrapper'}>
      {currentTabName !== 'enableBiometricTab' && (
        <ModalHeader title={salla.lang.get('blocks.header.login')} />
      )}
      
      {/* Tab 2 (Login By Mobile)*/}
      {isMobileAllowed ?
        <div className={generateTabClasses('login-phone')} data-name="login-phone"
          ref={mobileTab}>
          <form onSubmit={(event) => loginBySMS(event)} className={hasError && errorMessage ? 'has-error' : ''} method="POST">
            <label className="s-login-modal-label">{salla.lang.get('common.elements.mobile')}</label>
            <TelInput
              onKeyDown={e => typing(e)}
              phoneEntered={(phone) => setPhone(phone)}
              ref={loginTelInput}
              name="phone"
              salla={salla}
            />
            {hasError && errorMessage ? <span className="s-login-modal-error-message">
              {errorMessage}
            </span> : ''}
            {/* @ts-ignore */}
            <button className="mt-3 btn btn--primary" type="submit" disabled={isLoading || authenticatePasskeyMutation.isLoading} ref={smsBtn}>
              {isLoading || authenticatePasskeyMutation.isLoading ? <Loading size="sm" /> : salla.lang.get('blocks.header.enter')}
            </button>
          </form>
          {isEmailAllowed ?
            <button type="button" onClick={() => emailTab.current && showTab(emailTab.current)} className="s-login-modal-link">{salla.lang.get('blocks.header.login_by_email')}</button> : ''
          }
          <slot name="after-login-mobile" />
        </div> : ''}
      {isEmailAllowed ?
        <div className={generateTabClasses('login-email')} data-name="login-email"
          ref={emailTab}>
          <label className="s-login-modal-label">{salla.lang.get('common.elements.email')}</label>
          <form onSubmit={(event) => loginByEmail(event)} method="POST">
            <input type="email" ref={loginEmail} onKeyDown={e => typing(e)}

              placeholder="your@email.com"
              enterKeyHint="next"
              className={`s-login-modal-input s-ltr ${hasError ? 's-has-error' : ''}`} />
            {hasError && errorMessage ? <span className="s-login-modal-error-message">
              {errorMessage}
            </span> : ''}
            <button className="mt-3 btn btn--primary" disabled={isLoading || authenticatePasskeyMutation.isLoading} type="submit" ref={emailBtn}>
              {isLoading || authenticatePasskeyMutation.isLoading ? <Loading size="sm" /> : salla.lang.get('blocks.header.enter')}
            </button>
          </form>
          {isMobileAllowed ?
            <button type='button' onClick={() => mobileTab.current && showTab(mobileTab.current)}
              className="s-login-modal-link">{salla.lang.get('blocks.header.login_by_sms')}</button>
            : ''
          }
        </div> : ''}
      {/* Tab 4 (Verify OTP)*/}
      <div ref={verifyTab} className={generateTabClasses('otp')} data-name="otp">
        {showVerify && [<Verify key="verify" salla={salla} ref={verifyRef} supportWebAuth={supportWebAuth} verified={(res) => handleVerified(res)} />,
        <button key="phone-tab" type="button" onClick={() => handleBackButton()}
          className="s-login-modal-verify-back"
        >
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <title>arrow-right</title>
            <path d="M29.217 15.465c-0.019-0.044-0.056-0.077-0.080-0.119-0.067-0.116-0.139-0.227-0.236-0.317-0.011-0.009-0.013-0.024-0.024-0.033l-10.667-9.333c-0.553-0.484-1.396-0.429-1.881 0.125-0.484 0.555-0.428 1.396 0.127 1.881l7.996 6.997h-20.452c-0.737 0-1.333 0.597-1.333 1.333s0.596 1.333 1.333 1.333h20.452l-7.996 6.997c-0.555 0.485-0.611 1.327-0.127 1.881 0.264 0.3 0.633 0.455 1.004 0.455 0.312 0 0.625-0.109 0.877-0.331l10.667-9.333c0.011-0.009 0.015-0.024 0.024-0.033 0.097-0.091 0.169-0.201 0.236-0.317 0.024-0.041 0.060-0.075 0.080-0.119 0.073-0.163 0.116-0.343 0.116-0.533s-0.043-0.371-0.116-0.535z" />
          </svg>
        </button>]}
      </div>
      <div ref={registrationTab} data-name="registration" className={generateTabClasses('registration')}>
        <div>
          <label className="s-login-modal-label">{salla.lang.get('blocks.header.your_name')}</label>
          <input
            type="text"
            className="s-login-modal-input"
            ref={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
            onKeyDown={(e) => typing(e, newUser)}
            placeholder={salla.lang.get('pages.profile.first_name')}
          />
          <span className="s-login-modal-error-message" />
        </div>
        <div>
          <label className="s-login-modal-label">{salla.lang.get('pages.profile.last_name')}</label>
          <input
            type="text"
            className="s-login-modal-input"
            ref={lastName}
            onKeyDown={(e) => typing(e, newUser)}
            placeholder={salla.lang.get('pages.profile.last_name')}
          />
          <span className="s-login-modal-error-message" />
        </div>
        <div className={generateRegClasses('phone') + ' relative'}>
          <label className="s-login-modal-label">{salla.lang.get('common.elements.mobile')}</label>
          <TelInput
            onKeyDown={e => typing(e, newUser)}
            phoneEntered={(phone) => setPhone(phone)}
            ref={regTelInput}
            name="phone"
            salla={salla}
            isRegistration
          />
        </div>
        <div className={generateRegClasses('email')}>
          <label className="s-login-modal-label">{salla.lang.get('common.elements.email')}</label>
          <input
            type="email"
            ref={regEmail}
            onKeyDown={(e) => typing(e, newUser)}
            placeholder="your@email.com"
            className="s-login-modal-input s-ltr"
          />
          <span className="s-login-modal-error-message" />
        </div>
        <div className="s-login-modal-custom-fields" ref={customFieldsWrapperRef}>
          {customFields.map((field: CustomField, i) => (
            <React.Fragment key={i}>
              <label className="s-login-modal-label">{field.label}</label>
              {field.type === CustomFieldType.PHOTO ? (
                <FileUploader
                  fileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/gif']}
                  onUploadComplete={(value) => { console.log(value) }}
                  url={salla.url.get('upload-image')}
                  labelIdle={getFilepondPlaceholder()}
                  name='image'
                  salla={salla}
                />

              ) : (
                <input
                  onInput={(el) => (field.type === CustomFieldType.NUMBER ? salla.helpers.inputDigitsOnly(el.target) : {})}
                  onKeyDown={(e) => typing(e, newUser)}
                  className="s-login-modal-input s-ltr"
                  maxLength={field.length || 1000}
                  placeholder={field.description}
                  required={field.required}
                  title={field.label}
                  id={`${field.id}`}
                  type="text"
                />
              )}
              <span className="s-login-modal-error-message" />
            </React.Fragment>
          ))}
        </div>
        <button
          type="button"
          className="mt-3 btn btn--primary"
          disabled={isLoading}
          onClick={() => newUser()}
          ref={regBtn}
        >
          {isLoading ? <Loading size="sm" /> : salla.lang.get('blocks.header.register')}
        </button>
      </div>
      <BiometricCTA
        ref={enableBiometricTab}
        className={
          generateTabClasses('enableBiometricTab') +
          ' s-biometric-cta sm:py-16 py-8'
        }
        token={salla.storage.get('token')}
        locale={salla.config.get('user.language_code')}
      />
    </div>
  )
};

const ModalHeader = ({ title }: { title: string }) => {
  return (
    <div className="s-login-modal-header-inner">
      <span slot="icon" className="s-login-modal-header-icon">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <title>user</title>
          <path d="M16 16c4.412 0 8-3.588 8-8s-3.588-8-8-8-8 3.588-8 8 3.588 8 8 8zM16 2.667c2.941 0 5.333 2.392 5.333 5.333s-2.392 5.333-5.333 5.333-5.333-2.392-5.333-5.333 2.392-5.333 5.333-5.333zM16 18.667c-7.476 0-13.333 3.66-13.333 8.333v3.667c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-3.667c0-3.019 4.984-5.667 10.667-5.667s10.667 2.648 10.667 5.667v3.667c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-3.667c0-4.673-5.857-8.333-13.333-8.333z" />
        </svg>
      </span>
      <div className="s-login-modal-header-content">
        <div className="s-login-modal-title">{title}</div>
      </div>
    </div>
  );
};

export default Login;
export type { LoginProps };