import { useState, useCallback } from 'react';

interface MutationState<TData, TError> {
  isLoading: boolean;
  error: TError | null;
  data: TData | null;
}

interface MutationOptions<TData, TError> {
  onSuccess?: (data: TData) => void;
  onError?: (error: TError) => void;
}

function useMutation<TArgs, TData, TError = unknown>(
  mutationFn: (...args: TArgs[]) => Promise<TData>,
  options?: MutationOptions<TData, TError>
) {
  const [state, setState] = useState<MutationState<TData, TError>>({
    isLoading: false,
    error: null,
    data: null,
  });

  const mutate = useCallback(
    async (...args: TArgs[]) => {
      setState({ isLoading: true, error: null, data: null });

      try {
        const data = await mutationFn(...args);
        setState({ isLoading: false, error: null, data });

        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      } catch (error) {
        setState({ isLoading: false, error: error as TError, data: null });

        if (options?.onError) {
          options.onError(error as TError);
        }
      }
    },
    [mutationFn, options]
  );

  return {
    ...state,
    mutate,
  };
}

export default useMutation;
