const SuccessCheckmark: React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <svg
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
        className="w-20 h-20 text-green-500"
      >
        <g
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            className="circle animate-draw-circle"
            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
            style={{
              strokeDasharray: 76,
              strokeDashoffset: 76,
              animation: 'draw 1s forwards',
            }}
          />
          <path
            className="tick animate-draw-tick"
            d="M6.5 13.5L10 17l8.808621-8.308621"
            style={{
              strokeDasharray: 18,
              strokeDashoffset: 18,
              animation: 'draw 1s forwards 1s',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export default SuccessCheckmark;