import React, { forwardRef } from 'react';
import Loading from '../Loading';
import { isBiometricSupported } from './utils';
import { parentMessage } from '../../common/utils';
import { useTranslation } from 'react-i18next';

type BiometricCTAProps = React.HTMLAttributes<HTMLDivElement> & {
  registerPasskey?: () => void;
  loading?: boolean,
  passKeyCreated?: boolean,
  url?: string,
  token?: string,
  locale?: string
};

const BiometricCTA = forwardRef<HTMLDivElement, BiometricCTAProps>(
  ({ registerPasskey, loading, passKeyCreated, ...props }, ref) => {
    const { t } = useTranslation();
    if (!isBiometricSupported) return null;
    return (
      <div ref={ref} data-name="enableBiometricTab" {...props}>
        <p className="s-biometric-cta-title text-center leading-7 mb-3" dangerouslySetInnerHTML={{ __html: t('passkey_cta_desc') }}/>
        <button
          type="button"
          // onClick={() => parentMessage({ url: props.url,  }, 'open_page')}
          onClick={() => parentMessage({ token: props.token, locale: props.locale,  }, 'open_passkey_page')}
          disabled={loading}
          className="s-biometric-cta-button mt-3 btn btn--primary"
        >
          {loading ? <Loading size="sm" /> : t('passkey_cta_title')}
        </button>
      </div>
    );
  }
);

export default BiometricCTA;
