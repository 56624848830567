import React, { useEffect } from 'react';
import { registerPasskeyWithFetch } from '../components/Biometric/actions';
import useMutation from '../hooks/useMutation';
import SuccessCheckmark from '../components/icons/success-icon';
import ErrorIcon from '../components/icons/error-icon';
import { useTranslation } from 'react-i18next';
import { isPasskeyError, parentMessage } from '../common/utils';

export default function CreatePassKey({
  passKeyData
} : {
  passKeyData: any
}) {
  const { t } = useTranslation();
  const {
    mutate,
    isLoading: loading,
    data,
    error,
  } = useMutation<any, string, Error | any>(
    (headers) => registerPasskeyWithFetch(headers),
    {
      onSuccess: (data) => {
        setTimeout(
          () => handlePasskeyTabClose(passKeyData?.redirect as string),
          2000
        );
      },
      onError: (error: any) => {
        // if (error?.status === 401) {
        //   setTimeout(() => window.location.replace(redirect as string), 500);
        // }
      },
    }
  );

  const handlePasskeyTabClose = (url: string) => {
    if (window?.opener && !window?.opener.closed) {
      return parentMessage({ isPasskeyPage: true }, 'close_passkey');
    }

    window.location.replace(url)
  }

  useEffect(() => {
    window.focus();
    if (!passKeyData) return;
    handlePassKeyCreation(passKeyData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passKeyData]);

  const handlePassKeyCreation = (headers: any) => {
    const { token, store_id, locale } = headers;
    mutate({
      'Store-Identifier': store_id || '',
      'Accept-Language': locale || 'ar',
      Authorization: `Bearer ${token}`,
    });
  };

  if (loading) {
    return (
      <div className="relative w-full h-2 bg-gray-200 overflow-hidden">
        <div className="absolute top-0 left-0 h-full w-full bg-primary animate-loading"></div>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 h-lvh w-full flex flex-col items-center justify-center px-2 text-center">
      {error && !loading ? (
      <>
        <div className='max-w-[120px] mb-4'>
          <img className='max-h-16' src={passKeyData.store_logo} alt=''/>
        </div>
        <div className='bg-white p-8 rounded-md min-w-[340px]'>
          <ErrorIcon />
          <p className="text-red-500 text-base mt-4 max-w-[400px]">
            {isPasskeyError(error) ? t('cancel_error') : error?.error?.fields?.email[0] ||
              error?.message ||
              error?.error?.message ||
              error?.error?.code }
          </p>

          <div className="w-full flex flex-col items-center justify-center gap-3 mt-4">
            {![401, 422]?.includes(error?.status) ? (
              <button
                className="btn btn--primary"
                onClick={() => {
                  handlePassKeyCreation(passKeyData);
                }}
              >
                {t('try_again')}
              </button>
            ) : null}
            <button
              className="btn bg-white text-black opacity-60 border border-gray-200 rounded cursor-pointer"
              onClick={() => {
                handlePasskeyTabClose(passKeyData?.redirect as string);
              }}
            >
              {t('continue_shopping')}
            </button>
          </div>
        </div>
      </>
      ) : null}

      {data && !error && !loading ? (
        <div>
          <SuccessCheckmark />
          <p className="text-green-500 text-base mt-4">
           {t('passkey_success')}
          </p>
        </div>
      ) : null}
    </div>
  );
}
