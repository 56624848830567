import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_en from './en/translation.json';
import translations_ar from './ar/translation.json';

i18next.use(initReactI18next).init({
  debug: true,
  lng: 'ar',
  fallbackLng: 'ar',
  resources: {
    en: {
      translation: translations_en
    },
    ar: {
      translation: translations_ar
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});