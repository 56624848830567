import { useEffect, useMemo } from 'react';
import { parentMessage } from './utils';
import { useLocation } from 'react-router-dom';

function useSalla() {
  const { pathname } = useLocation();
  const salla = useMemo(() => {
    return (window as any).salla || (window as any).Salla || {};
  }, []);

  useEffect(() => {
    if (salla.is_accounts_loaded) {
      return;
    }

    // Send ready and storage events
    parentMessage(pathname === '/create-passkey' ? { isPasskeyPage: true } : {}, 'ready');
    
    const storageObject = {
      user: salla.storage.get('user'),
      token: salla.cookie.get('s-token') || salla.storage.get('token'),
    };

    // Avoid sending undefined by removing it
    if (!storageObject.user) {
      delete storageObject.user;
    }
    if (!(storageObject.token?.length > 10)) {
      delete storageObject.token;
    }
    
    if (Object.keys(storageObject).length) {
      parentMessage(storageObject, 'storage');
    }

    // Setup logger
    salla.logger.addPrefix(['%cSalla-Accounts', 'margin-left: 5px;font-weight:bold; border:1px solid #bbf3e5; padding: 2px 6px; border-radius: 5px; color:#bbf3e5']);

    // Disable cart API call
    salla.api.cart.latest = () => ({ data: { cart: { id: null } } });

    // Set custom notifier
    salla.notify.setNotifier((message: string, type: unknown, data: unknown) =>
      parentMessage({ message, type, data: JSON.parse(JSON.stringify(data)) }, 'notify')
    );

    // Whitelist certain events
    const handleEvent = (event: string, ...payload: unknown[]) => {
      if (['auth::verified', 'profile::info.fetched', 'auth::logged.in', 'api::token.injected'].includes(event)) {
        parentMessage({ event, payload }, 'dispatchEvent');
      }
    };

    salla.event.onAny(handleEvent);

    // Mark accounts as loaded
    salla.is_accounts_loaded = true;

    // Cleanup event listener when the component unmounts
    return () => {
      salla.event.offAny(handleEvent);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salla]);

  return salla;
}

export default useSalla;
