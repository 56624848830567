type ExtractVariables<T> = T extends { params: object } ? T['params'] : never;

export async function sallaFetch<T>({
  cache = 'no-store',
  headers,
  params,
  method = 'GET',
  endpoint,
}: {
  cache?: RequestCache;
  headers?: HeadersInit;
  params?: ExtractVariables<T>;
  method?: string;
  endpoint?: string;
}): Promise<{ status: number; data: T } | never> {
  try {
    const { token, store_id, locale } = window?.passkey ?? {};

    const result = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: method,
      headers: {
        'Store-Identifier': store_id+'' || '',
        Accept: 'application/json, text/plain, */*',
        'Accept-Language': locale || 'ar',
        'S-Source': 'twilight',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Cf-Access-Client-Id': process.env.REACT_APP_CLIENT_ID as string,
        'Cf-Access-Client-Secret': process.env.REACT_APP_CLIENT_SECRET as string,
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      ...(params && {
        body: JSON.stringify(params),
      }),
      cache,
    });

    const data: any = await result.json();

    if (!result.ok) {
      throw data;
    }

    return {
      status: result.status,
      data,
    };
  } catch (e: unknown) {
    console.error('Error fetching user data:', e);
    throw e;
  }
}

// function getQueryParams(): Record<string, string> {
//   const params = new URLSearchParams(window.location.search);

//   return {
//     ...(params.get('token') && { token: params.get('token')! }),
//     ...(params.get('si') && { store_id: params.get('si')! }),
//     ...(params.get('locale') && { store_id: params.get('locale')! }),
//   };
// }
