// types
interface CustomPublicKeyCredentialDescriptor
  extends Omit<PublicKeyCredentialDescriptor, 'id'> {
  id: string | BufferSource;
}

interface CustomPublicKeyCredentialCreationOptions
  extends Omit<
    PublicKeyCredentialCreationOptions,
    'challenge' | 'user' | 'excludeCredentials'
  > {
  challenge: string | BufferSource;
  user: {
    id: string | BufferSource;
    name: string;
    displayName: string;
  };
  excludeCredentials?: CustomPublicKeyCredentialDescriptor[];
}

export interface CustomPublicKeyCredentialRequestOptions
  extends Omit<
    PublicKeyCredentialRequestOptions,
    'challenge' | 'allowCredentials'
  > {
  challenge: string | BufferSource;
  allowCredentials?: CustomPublicKeyCredentialDescriptor[];
  key: number
}

export type creationCredential = {
  authenticatorAttachment?: string;
  id: string;
  rawId: string;
  type: string;
  response?: {
    clientDataJSON: string;
    attestationObject: string;
  };
  name: string;
};

type requestCredential = {
  authenticatorAttachment?: string;
  id: string;
  rawId: string;
  type: string;
  response?: {
    clientDataJSON: string;
    authenticatorData: string;
		signature: string,
		userHandle: string | null
  };
};

export const isBiometricSupported: boolean = !!window.PublicKeyCredential;

// export const checkBiometricCompatibility = async (): Promise<boolean> => {
//   // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
//   // `isUserVerifyingPlatformAuthenticatorAvailable` means the feature detection is usable.
//   if (
//     window.PublicKeyCredential &&
//     PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable
//   ) {
//     // Check if user verifying platform authenticator is available.
//     const supported =
//       await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
//     isBiometricSupported = supported;
//     return supported ? true : false;
//   } else {
//     isBiometricSupported = false;
//     return false;
//   }
// };

// // Call the function to initialize the variable
// checkBiometricCompatibility();
// export { isBiometricSupported };

const _bufferEncode = (value: ArrayBuffer): string => {
  const uint8Array = new Uint8Array(value);
  const charArray = Array.from(uint8Array, (byte) => String.fromCharCode(byte));
  return window.btoa(charArray.join(''));
};

const _bufferDecode = (value: string): Uint8Array => {
  const decodedString = window.atob(value);
  return Uint8Array.from(decodedString, (c) => c.charCodeAt(0));
};

const _base64Decode = (input: string): string => {
  // Replace non-compatible chars with base64 standard chars
  input = input.replace(/-/g, '+').replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        'InvalidLengthError: Input base64url string is the wrong length to determine padding'
      );
    }
    input += '='.repeat(4 - pad);
  }

  return input;
};

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile)))/i.test(userAgent);
  const isMobile =
    /(mobile|iphone|ipod|android(.*mobile)|blackberry|nokia)/i.test(userAgent);

  if (isTablet) {
    return 'Tablet';
  } else if (isMobile) {
    return 'Mobile';
  } else {
    return 'Laptop/Desktop';
  }
};

export const decodeCreationOptions = (
  options: CustomPublicKeyCredentialCreationOptions
) => {
  options.challenge = _bufferDecode(_base64Decode(options.challenge as string));
  options.user.id = _bufferDecode(options.user.id as string);

  if (options.excludeCredentials && options.excludeCredentials.length) {
    for (let cred of options.excludeCredentials) {
      cred.id = _bufferDecode(_base64Decode(cred.id as string));
    }
  }

  return options as PublicKeyCredentialCreationOptions;
};

export const decodeRequestOptions = (
  options: CustomPublicKeyCredentialRequestOptions
) => {
  options.challenge = _bufferDecode(_base64Decode(options.challenge as string));

  if (options.allowCredentials && options.allowCredentials.length) {
    for (let cred of options.allowCredentials) {
      cred.id = _bufferDecode(_base64Decode(cred.id as string));
    }
  }

  return options as PublicKeyCredentialRequestOptions;
};

export const encodeCreationCredential = (credential: PublicKeyCredential) => {
  const creationCredential: creationCredential = {
    id: credential.id,
    rawId: _bufferEncode(credential.rawId),
    type: credential.type,
    name: getDeviceType(),
  };

  if (credential.authenticatorAttachment) {
    creationCredential.authenticatorAttachment =
      credential.authenticatorAttachment;
  }

  if (
    credential.response &&
    credential.response instanceof AuthenticatorAttestationResponse
  ) {
    const clientDataJSON = _bufferEncode(
      credential.response.clientDataJSON
    ).replace(/=/g, '');
    const attestationObject = _bufferEncode(
      credential.response.attestationObject
    );
    creationCredential.response = {
      clientDataJSON,
      attestationObject,
    };
  }

  return creationCredential;
};

export const encodeRequestCredential = (credential: PublicKeyCredential) => {
  const requestCredential: requestCredential = {
    id: credential.id,
    rawId: _bufferEncode(credential.rawId),
    type: credential.type,
  };

  if (credential.authenticatorAttachment) {
    requestCredential.authenticatorAttachment =
      credential.authenticatorAttachment;
  }

  if (
    credential.response &&
    credential.response instanceof AuthenticatorAssertionResponse
  ) {
    const clientDataJSON = _bufferEncode(
      credential.response.clientDataJSON
    ).replace(/=/g, '');
    const authenticatorData = _bufferEncode(
      credential.response.authenticatorData
    ).replace(/=/g, '');
    const signature = _bufferEncode(credential.response.signature);
    const userHandle = credential.response.userHandle
      ? _bufferEncode(credential.response.userHandle)
      : null;
    requestCredential.response = {
      clientDataJSON,
      authenticatorData,
      signature,
      userHandle,
    };
  }

  return requestCredential;
};
