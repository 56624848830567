import { useState, useEffect, useCallback } from 'react';
import useSalla from './useSalla';
import { log, error, toggleDarkMode } from './utils';

function useSetupApp(initialData: any) {
    const [isReady, setIsReady] = useState(false);
    const [supportWebAuth, setSupportWebAuth] = useState(false);
    const [withoutReload, setWithoutReload] = useState(false);
    const [data, setData] = useState(() => initialData);
    const salla = useSalla();
    const startTheAppCallback = useCallback(() => {
        if (!data) {
            return;
        }
        setSupportWebAuth(data.supportWebAuth);
        setWithoutReload(data.withoutReload);
        toggleDarkMode(data.isDark);
        document.documentElement.dir = data.lang === 'ar' ? 'rtl' : 'ltr';
        // data.headers && salla.api.setHeaders(data.headers);
        //@ts-ignore
        Object.entries(data.storage).forEach(([key, value]) => localStorage.setItem(key, value));
        if(data.storage.token){
            salla.cookie.set('s-token', data.storage.token);
        }


        const headers = salla.api.getHeaders();

        Promise.resolve()
            .then(() => {
                if (Object.keys(data.settings).length) {
                    console.log('Twilight config passed from parent 🤩');
                    return data.settings;
                }
                console.log('⚡️ Getting Twilight config via api request...');

                return fetch(`${data.apiUrl}/store/settings`, {
                    headers: {
                        "accept-language": data.lang || 'ar',
                        ...headers,
                        "Store-Identifier": data.id,
                    },
                }).then(res => res.json()).then(res => res.data);
            })
            .then((config) => {
                let user = config.user;
                let isUserIdValid = false;

                if (user) {
                    isUserIdValid = /^\d+$/.test(user.id);
                }

                if (!isUserIdValid) {
                    const storedUser = salla.storage.get('user');
                    if (storedUser) {
                        user = storedUser;
                    }
                }

                config.user = user;
                salla.init(config);
            })
            .then(() => salla.onReady())
            .then(() => salla.lang.onLoaded())
            .then(() => salla.config.get('theme.color') as { [key: string]: string } || {})
            .then(colors => Object.entries(colors).forEach(([key, value]) => document.documentElement.style.setProperty(`--color-${key.replace(/_/g, '-')}`, value)))
            .then(() => log('Salla Twilight initialized successfully', salla))
            .then(() => setIsReady(salla.config.isGuest() || window.location.pathname.includes('logout')))//set ready to show the login form only if the user is not logged-in
            .catch(er => error('Error initializing Salla Twilight', er));
    }, [data, salla]);
    useEffect(() => startTheAppCallback(), [startTheAppCallback]);
    const startTheApp = (data: any) => setData(data);


    return { salla, isReady, supportWebAuth, withoutReload, startTheApp };
}

export default useSetupApp;