import './i18n/config';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Logout from './components/Logout';
import '@salla.sa/twilight';
import './App.scss';
import Loading from './components/Loading';
import useSetupApp from './common/useSetupApp';
import { parentMessage, inIframe, toggleDarkMode } from './common/utils';
import CreatePassKey from './views/create-pass-key';
import { useTranslation } from 'react-i18next';

const handleMessageWidth = (width: number) => {
  document.documentElement.style.setProperty('--parent-width', `${width}px`);
  width <= 786 && document.body.classList.add('is-mobile');
};

const isInIframe = inIframe();

function App() {
  const { salla, isReady, supportWebAuth, withoutReload, startTheApp } = useSetupApp(undefined);
  const [passkeyData, setPasskeyData] = useState(null);
  const { i18n } = useTranslation();
  const container = React.createRef<HTMLDivElement>();
  const { pathname } = useLocation();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => parentMessage({ height: container.current?.scrollHeight }, 'height'));
    container.current && resizeObserver.observe(container.current);

    const handleMessage = (event: MessageEvent) => {
      if (event.data.source !== 'login' && event.data.source !== 'logout') {
        return;
      }

      const data = event.data.data;
      const action = event.data.type;
      if (action === 'width') {
        return handleMessageWidth(data);
      }
      if (action === 'init') {
        i18n.changeLanguage(data.lang);
        return startTheApp(data);
      }
      if (action === 'modal_closed') {
        const isBiometricCtaOpened = document.querySelector('.s-biometric-cta')?.classList.contains('s-show');
        if (!isBiometricCtaOpened) return;
        return salla.auth.canRedirect && !withoutReload && parentMessage({}, 'reload');
      }
      if (action === 'init_passkey_page') {
        Object.entries(data.store_colors).forEach(([key, value]) => document.documentElement.style.setProperty(`--color-${key.replace(/_/g, '-')}`, value as string))
        window.passkey = data;
        i18n.changeLanguage(data.locale);
        setPasskeyData(data);
        return;
      }
      if (action === 'darkMode') {
        return toggleDarkMode(data.isDark);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
    // eslint-disable-next-line
  }, [salla, container]);

  if (!isReady && !isInIframe && pathname === '/create-passkey') {
    return (
      <div className="App">
        <Routes>
          <Route path="/create-passkey" element={<CreatePassKey passKeyData={passkeyData} />} />
        </Routes>
      </div>
    );
  }

  if (!isReady) {
    return isInIframe ? (
      <div className="flex flex-col items-center justify-center h-full fill-primary">
        <Loading size="sm" color="primary" />
      </div>
    ) : null;
  }

  return (
    <div className="App" ref={container}>
      <Routes>
        <Route
          path="/"
          element={
            <Login
              isEmailAllowed={salla.config.get('store.settings.auth.email_allowed')}
              isMobileAllowed={salla.config.get('store.settings.auth.mobile_allowed')}
              isEmailRequired={salla.config.get('store.settings.auth.is_email_required')}
              supportWebAuth={supportWebAuth}
              withoutReload={withoutReload}
              salla={salla}
              inline={true}
            />
          }
        />
        <Route path="/logout" element={<Logout salla={salla} />} />
      </Routes>
    </div>
  );
}

export default App;
